import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../styles/index.css';

function Index() {
  const [date, setDate] = useState(null);
  useEffect(() => {
    async function getDate() {
      const res = await fetch('/api/date');
      const newDate = await res.text();
      setDate(newDate);
    }
    getDate();
  }, []);
  return (
    <main>
      <Helmet>
        <title>沽默清尘</title>
      </Helmet>
      <h1> Gatsby 博客 by <a herf="https://github.com/gzg1023/">gzg1023</a></h1>
    </main>
  );
}

export default Index;
